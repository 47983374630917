import { Box } from "@mui/material";
import Content from "./components/content/content";
import { createTheme, ThemeProvider, CssBaseline } from "@mui/material";
import { useEffect } from "react";
import { analytics } from "./firebaseConfig";
import { logEvent } from "firebase/analytics";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LerutkaMessage from "./components/lerik/LerutkaMessage";
import Valentine from "./components/lerik/Valentine";
import Momic from "./components/mom/momic";
const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#61dafb",
    },
    secondary: {
      main: "#282c34",
    },
    background: {
      default: "#282c34",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#7fffd4",
    },
  },
  typography: {
    fontFamily: ["Source Code Pro", "monospace"].join(","),
  },
});

function App() {
  useEffect(() => {
    logEvent(analytics, "app_opened");
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <Box className="App">
                <Content />
              </Box>
            }
          />
          <Route path="lerik" element={<LerutkaMessage />} />
          <Route path="/valentine" element={<Valentine />} />
          <Route path="/mom" element={<Momic />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
