// @ts-nocheck
import { useState } from "react";
import "./styles.css";

export default function Valentine() {
  const [accepted, setAccepted] = useState(false);
  const [yesSize, setYesSize] = useState(1);

  const increaseSize = () => {
    setYesSize((prevSize) => prevSize * 1.2);
  };

  return (
    <div className="flex flex-col items-center gap-4 p-6 text-center">
      {/* Question Section */}
      <div className="question">
        <p className="text-xl font-semibold">
          {accepted ? "YAY!" : "Will you be my valentine?"}
        </p>

        {/* Image Handling */}
        {!accepted && (
          <img
            src="/images/tiny-green-frog-teeny-tiny-green-frog.gif"
            className="first-image"
            alt="Cute frog"
          />
        )}
        {accepted && (
          <>
            <img
              src="/images/tiny-green-frog-frog-love.gif"
              className="image second-image"
              alt="Frog love"
            />
            <img
              src="/images/730d75ed729397068c7a89fa7476e305.gif"
              className="image third-image"
              alt="Happy celebration"
            />
          </>
        )}
      </div>

      {/* Buttons Section */}
      <div className="buttons flex gap-4 mt-4">
        <button
          className="yes bg-green-500 text-white font-bold py-2 px-4 rounded transition-transform"
          style={{ transform: `scale(${yesSize})` }}
          onClick={() => setAccepted(true)}
        >
          Yes
        </button>
        {!accepted && (
          <button
            className="no bg-red-500 text-white font-bold py-2 px-4 rounded"
            onClick={increaseSize}
          >
            No
          </button>
        )}
      </div>
    </div>
  );
}
