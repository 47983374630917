// @ts-nocheck
import type React from "react";
import { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
type MessagePair = {
  text: string;
  image: string;
};
const messages: MessagePair[] = [
  {
    text: "Hey, știu că acum îți este dificil",
    image:
      "https://i.pinimg.com/736x/71/c5/b0/71c5b04c7e5af3685f3ad4c8aa4dcf9d.jpg",
  },
  {
    text: "Și ai multe probleme",
    image:
      "https://i.pinimg.com/736x/f6/b3/53/f6b35317598efaba1ecf9777765505d6.jpg",
  },
  {
    text: "Îmi fac griji pentru tine",
    image:
      "https://i.pinimg.com/736x/77/63/7c/77637c33c67e2495acddbdf39242f58a.jpg",
  },
  {
    text: "Și doresc să te susțin",
    image:
      "https://i.pinimg.com/736x/c9/dd/18/c9dd18cf4fd3eee8ba6db0ccde268fe0.jpg",
  },
  {
    text: "Vei rezolva aboslut orice dificultate",
    image:
      "https://i.pinimg.com/736x/f3/fb/35/f3fb35ba9b3a511f521d29db522753b0.jpg",
  },
  {
    text: "Examene, licența, mutatul, probleme de sănătate",
    image:
      "https://i.pinimg.com/736x/86/04/f9/8604f9829ccfb5ad6bee27adcefb156e.jpg",
  },
  {
    text: "Vei face față tuturor lucrurilor",
    image:
      "https://i.pinimg.com/736x/15/b7/ea/15b7ea6c00714ab3f195159e4cee012f.jpg",
  },
  {
    text: "Pentru că ești minunată",
    image:
      "https://i.pinimg.com/736x/6c/51/2a/6c512aaa0791850e63e29ee9960e160c.jpg",
  },
  {
    text: "Am încredere în tine!",
    image:
      "https://i.pinimg.com/736x/86/a3/fb/86a3fbcf20ce2d566015017faa15017b.jpg",
  },
  {
    text: "A fost foarte plăcut să vorbim ieri",
    image:
      "https://i.pinimg.com/736x/87/ee/28/87ee283ba8763974d99f81b01ec2b72a.jpg",
  },
  {
    text: "Mă bucur mult",
    image:
      "https://i.pinimg.com/736x/6b/96/11/6b9611edd8334e26d9be083e5ee9c733.jpg",
  },
  {
    text: "Și acum dacă mai ții minte",
    image:
      "https://i.pinimg.com/736x/ae/4d/30/ae4d3093835e6e791aa73c0e5dddd30b.jpg",
  },
  {
    text: "Mai am un lucru pentru tine",
    image:
      "https://i.pinimg.com/736x/7a/84/20/7a8420f8905038a9b3d9d3f9901fb615.jpg",
  },
];
const LerutkaMessage: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  let navigate = useNavigate();
  const handleClick = () => {
    if (currentIndex < messages.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "#debd85",
        padding: 2,
      }}
    >
      <AnimatePresence mode="wait">
        <motion.div
          key={currentIndex}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{ duration: 0.5 }}
        >
          <Box
            onClick={handleClick}
            sx={{
              backgroundColor: "white",
              alt: "message",
              borderRadius: 4,
              padding: 4,
              maxWidth: 400,
              textAlign: "center",
            }}
          >
            <Typography variant="h5" color={"black"} gutterBottom>
              {messages[currentIndex].text}
            </Typography>
            <Box
              component="img"
              src={messages[currentIndex].image}
              sx={{
                width: "100%",
                height: "auto",
                maxHeight: "500px",
                marginBottom: 2,
                borderRadius: 2,
              }}
            />
            {currentIndex === messages.length - 1 && (
              <Box>
                <Typography variant="h6" color="primary">
                  😊
                </Typography>
                <Button onClick={() => navigate("/valentine")}>
                  Și încă ceva
                </Button>
              </Box>
            )}
          </Box>
        </motion.div>
      </AnimatePresence>
    </Box>
  );
};
export default LerutkaMessage;
