// @ts-nocheck
import type React from "react";
import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";

import nyhavn from "../../assets/nyhavn.jpg";
import chill from "../../assets/chill.jpg";
import pisu from "../../assets/pisu.jpg";
import happy from "../../assets/happy.jpg";
import martisor from "../../assets/martisor.jpg";
import family from "../../assets/family.jpg";
import brasov from "../../assets/brasov.jpg";
import hug from "../../assets/hug.jpg";

type MessagePair = {
  text: string;
  image: string;
};
const messages: MessagePair[] = [
  {
    text: "Hello, stii ce zi mirobolanta este azi",
    image: pisu,
  },
  {
    text: "8 martie, valeu, stim ca trebuie mereu sa fim nice, dar azi tot merge",
    image: happy,
  },
  {
    text: "Sa fii mereu chill",
    image: chill,
  },
  {
    text: "Și sa calatorim cat mai mult",
    image: martisor,
  },
  {
    text: "Si sa petrecem timpul cool",
    image: family,
  },
  {
    text: "Si mereu happy",
    image: nyhavn,
  },
  {
    text: "Te apreciem foarte mult",
    image: brasov,
  },
  {
    text: "Si te iubim",
    image: hug,
  },
];
const Momic: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const handleClick = () => {
    if (currentIndex < messages.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "#debd85",
        padding: 2,
      }}
    >
      <AnimatePresence mode="wait">
        <motion.div
          key={currentIndex}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{ duration: 0.5 }}
        >
          <Box
            onClick={handleClick}
            sx={{
              backgroundColor: "white",
              alt: "message",
              borderRadius: 4,
              padding: 4,
              maxWidth: 400,
              textAlign: "center",
            }}
          >
            <Typography variant="h5" color={"black"} gutterBottom>
              {messages[currentIndex].text}
            </Typography>
            <Box
              component="img"
              src={messages[currentIndex].image}
              sx={{
                width: "100%",
                height: "auto",
                maxHeight: "500px",
                marginBottom: 2,
                borderRadius: 2,
              }}
            />
            {currentIndex === messages.length - 1 && (
              <Box>
                <Typography variant="h6" color="primary">
                  😊
                </Typography>
              </Box>
            )}
          </Box>
        </motion.div>
      </AnimatePresence>
    </Box>
  );
};
export default Momic;
